import { defineMessages } from 'react-intl';
import {
  ElectricityDeviceCategory,
  GasDeviceCategory,
  Mounting,
  OtherDeviceCategory,
} from '@enmon/commons/lib/shared/meter';

export const SectionMain = defineMessages({
  id: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.id',
  },
  main: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.main',
  },
  meters: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.meters',
  },
  meterType: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.meterType',
  },
  monitoredEntity: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.monitoredEntity',
  },
  accessibility: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.accessibility',
  },
  owner: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.owner',
  },
  tenant: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.tenant',
  },
  building: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.building',
  },
  buildingFloor: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.buildingFloor',
  },
  groundFloor: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.groundFloor',
  },
  isSubmeter: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.isSubmeter',
  },
  isSuggestedNewMeter: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.isSuggestedNewMeter',
  },
  meterMountType: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.meterMountType',
  },
  remoteReadingOwner: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.remoteReadingOwner',
  },
  note: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.note',
  },
  yes: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.yes',
  },
  no: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMain.no',
  },
});

export const SectionApiKey = defineMessages({
  title: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionApiKey.title',
  },
  missingQR: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionApiKey.missingQR',
  },
});

export const SectionDetails = defineMessages({
  title: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionDetails.title',
  },
  manufacturer: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionDetails.manufacturer',
  },
  meterModel: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionDetails.meterModel',
  },
  serialNumber: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionDetails.serialNumber',
  },
  owner: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionDetails.owner',
  },
  manufactured: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionDetails.manufactured',
  },
  calibrated: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionDetails.calibrated',
  },
  yes: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionDetails.yes',
  },
  no: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionDetails.no',
  },
});

export const ColdHeatCard = defineMessages({
  title: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ColdHeatCard.title',
  },
  diameterNominal: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ColdHeatCard.diameterNominal',
  },
  flowrate: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ColdHeatCard.flowrate',
  },
  flange: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ColdHeatCard.flange',
  },
  yes: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ColdHeatCard.yes',
  },
  no: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ColdHeatCard.no',
  },
  installationPosition: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ColdHeatCard.installationPosition',
  },
  layLength: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ColdHeatCard.layLength',
  },
  orientation: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ColdHeatCard.orientation',
  },
  resistance: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ColdHeatCard.resistance',
  },
});

export const ElectricityCard = defineMessages({
  title: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.title',
  },
  isDirect: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.isDirect',
  },
  measuringCurrentTransformer: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.measuringCurrentTransformer',
  },
  yesDirect: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.yesDirect',
  },
  noDirect: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.noDirect',
  },
  deviceCategory: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.deviceCategory',
  },
  [ElectricityDeviceCategory.Basic]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.basic',
  },
  [ElectricityDeviceCategory.FourQuadrants]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.fourQuadrants',
  },
  [ElectricityDeviceCategory.Multimeter]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.multimeter',
  },
  [ElectricityDeviceCategory.SmartCircuitBreaker]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.smartCircuitBreaker',
  },
  phases: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.phases',
  },
  mounting: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.mounting',
  },
  [Mounting.DIN]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.DIN',
  },
  [Mounting.Cross]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.cross',
  },
  width: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.width',
  },
  tariffs: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.tariffs',
  },
  i_range: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.ElectricityCard.i_range',
  },
});

export const GasCard = defineMessages({
  title: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.title',
  },
  diameterNominal: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.diameterNominal',
  },
  deviceCategory: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.deviceCategory',
  },
  [GasDeviceCategory.Membrane]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.membrane',
  },
  [GasDeviceCategory.Turbine]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.turbine',
  },
  [GasDeviceCategory.Rotary]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.rotary',
  },
  [GasDeviceCategory.Quantometer]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.quantometer',
  },
  orientation: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.orientation',
  },
  layLength: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.layLength',
  },
  gSize: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.gSize',
  },
  pMax: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.pMax',
  },
  qMax: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.qMax',
  },
  qMin: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.GasCard.qMin',
  },
});

export const WaterCard = defineMessages({
  title: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.WaterCard.title',
  },
  diameterNominal: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.WaterCard.diameterNominal',
  },
  flowrate: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.WaterCard.flowrate',
  },
  layLength: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.WaterCard.layLength',
  },
  orientation: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.WaterCard.orientation',
  },
  hot_water: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.WaterCard.hot_water',
  },
  flange: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.WaterCard.flange',
  },
  yes: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.WaterCard.yes',
  },
  no: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.WaterCard.no',
  },
});

export const OtherCard = defineMessages({
  title: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.OtherCard.title',
  },
  device_category: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.OtherCard.device_category',
  },
  [OtherDeviceCategory.Gateway]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.OtherCard.gateway',
  },
  [OtherDeviceCategory.LoRaGateway]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.OtherCard.loraGateway',
  },
  [OtherDeviceCategory.LoRaGatewayPowerSupply]: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.OtherCard.loraGatewayPowerSupply',
  },
});

export const SectionMeterTypeSpecific = defineMessages({
  title: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMeterTypeSpecific.title',
  },
  unsupportedMeterType: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionMeterTypeSpecific.unsupportedMeterType',
  },
});

export const SectionPhotos = defineMessages({
  title: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionPhotos.title',
  },
  missingPhotos: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.SectionPhotos.missingPhotos',
  },
});

export const MapContent = defineMessages({
  title: {
    id: 'app.inventoryLocation.Page.inventoryMeterDetail.Map.title',
  },
});
